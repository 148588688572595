const api = "https://cereal-dev-api.cereal-dev.lz.changiairport.com";
const config = {
  url: "https://cereal-dev-api.cereal-dev.lz.changiairport.com",
  api_endpoint: {
    CCTV_ENDPOINT: "/api-cctv/cctv",
    CCTV_ENDPOINT_BY_USER: "/api-cctv/cctv_by_user_package",
    CCTV_STREAM_ENDPOINT: "/api-cctv/cctv/stream",
    ANNOTATION_ENDPOINT: "/api-markup/markup",
    INCIDENT_LIST_ENDPOINT: "/api-incidents/incidents",
    INCIDENT_FILTER_ENDPOINT: "/api-incidents/incidents/filter",
    INCIDENT_COUNT_ENDPOINT: "/api-incidents/incidents/count",
    INCIDENT_DELETE_ENDPOINT: "/api-incidents/incidents/delete",
    UPLOAD_ATTACHMENT_ENDPOINT: "/api-incidents/upload_attachment",
    UPLOAD_URL_ENDPOINT_INCIDENT: "/api-incidents/upload_url",
    ADD_INCIDENT_ENDPOINT: "/api-incidents/incidents/add",
    GET_INCIDENT_ENDPOINT: "/api-incidents/incidents/get",
    UPDATE_INCIDENT_ENDPOINT: "/api-incidents/incidents/update",
    CREATE_INCIDENT_UPDATES_ENDPOINT: "/api-incidents/updates/create",
    GET_INCIDENT_UPDATES_ENDPOINT: "/api-incidents/updates",
    SAVE_USER_DEVICE_TOKEN_ENDPOINT: "/api-users/device_token",
    GET_USERS_ENDPOINT: "/api-users/users",
    GET_INCIDENT_OVERVIEW_ENDPOINT: "/api-incidents/incidents/dashboard",
    GET_AUDIT_TRAIL_ENDPOINT: "/api-audits/get_audits",
    GET_ECM_DRAIN_ENDPOINT: "/api-ecm-drain/ecm-drains",
    GET_ECM_CASEREPORT_ENDPOINT: "/api-ecm-drain/ecm-drains/case_reports",
    GET_SITREP_ENDPOINT: "/api-sitrep/sitrep/list",
    GET_WEATHER_ENDPOINT: "/api-weather/get_weather_data",
    UPLOAD_ATTACHMENT_SITREP_ENDPOINT: "/api-sitrep/upload_attachment",
    ADD_SITREP_ENDPOINT: "/api-sitrep/sitrep/add",
    GET_SITREPID_ENDPOINT: "/api-sitrep/sitrep/get",
    EDIT_SITREPID_ENDPOINT: "/api-sitrep/sitrep/update",
    EXPORT_INCIDENT_LIST_ENDPOINT: "/api-exports/incident-export",
    EXPORT_WORK_LIST_ENDPOINT: "/api-exports/wdc-export",
    Get_USER_ENDPOINT: "/api-users/getUser",
    GET_ECM_DETAIL_ENDPOINT: "/api-ecm-drain/ecm-drains/",
    GET_SITREP_WITH_PAGESIZE_ENDPOINT: "/api-sitrep/sitrep/listpagination",
    UPLOAD_USER_IMAGE: "/api-users/upload_url",
    SUBMIT_FEEDBACK_ENDPOINT: "/api-users/submit_feedback",
    UPDATE_USER_DETAIL: "/api-users/update_info",
    LIST_INBOX_ENDPOINT: "/api-notifications/all",
    GET_WORKDECLARATION_LIST: "/api-work-declaration/work_declaration/filter",
    GET_WORKDECLARATION_SETTING:
      "/api-work-declaration/work_declaration/settings",
    USER_REG_COMPANY_LIST: "/api-user-registration/company/list",
    USER_REG_USER_GROUP_LIST: "/api-user-registration/user_group/list",
    USER_REG_CREATE_REGISTRATION: "/api-user-registration/registration/create",
    USER_REG_VERIFY_REGISTRATION: "/api-user-registration/registration/verify",
    VERIFY_AUTH_CODE: "/api-users/verify_auth_code",
    FORGOT_PASSWORD: "/api-users/forgot_password",
    SET_PASSWORD: "/api-users/set_password",
    DELETE_INCIDENT_ENDPOINT: "/api-incidents/incidents/delete",
    CHANGE_PASSWORD: "/api-users/change_password",
    GET_USERS_LIST_ENDPOINT: "/api-users/users_list",
    GET_CONFIGURATION_ENDPOINT: "/api-users/configuration",
    CHECK_PASSWORD: "/api-users/check_password",
    GET_USER_ALT_ENDPOINT: "/api-users/getUserAlt",
    DISABLE_ACCOUNT_ENDPOINT: "/api-users/disable_account",
    IS_DISABLED_ACCOUNT_ENDPOINT: "/api-users/is_disabled_account",
    GLOBAL_SIGN_OUT_ENDPOINT: "/api-users/global_sign_out",
    USER_SESSION_ENDPOINT: "/api-users/user_session",
    USER_GUIDE: "/api-users/get_cdn_token",
    ECM_CASEREPORT_HISTORY_ENDPOINT:
      "/api-ecm-drain/ecm-drains/manage_manual_readings",
    ECM_CASE_REPORT_EXPORT_ENDPOINT: "/api-exports/ecm-case-reports-export",
    WORK_DECLARATION_OVERVIEW:
      "/api-work-declaration/work_declaration/dashboard",
    GET_MAP_LAYER_ENDPOINT: "/api-markup/get_map_layers",
    INPUT_SELECTION: "/api-ohs/input-selection",
    OHS_CREATE_ENDPOINT: "/api-ohs/inspection",
    OHS_APPROVAL_ENDPOINT: "/api-ohs/inspection/approvals",
    GET_OHS_ENDPOINT: "/api-ohs/inspections",
    PUT_OHS_ENDPOINT: "/api-ohs/inspection",
    GET_OHS_FILTER_ENDPOINT: "/api-ohs/inspections/filter",
    OHS_EXPORT: "/api-exports/ohs-export",
    OHS_LIST_ENDPOINT: "/api-ohs/inspections",
    OHS_EMAIL_ENDPOINT: "/api-ohs/inspection/",
    OHS_DASHBOARD_ENDPOINT: "/api-ohs/inspection/dashboard",
    DASHBOARD_QUICKSIDE: "/api-users/access_quicksight_dashboard",
    OHS_JSON_ENDPOINT: "/ohs/filter.json",
    DELETE_OHS_ENDPOINT: "/api-ohs/inspection",
    WMS_ENDPOINT: "/api-markup/WMS_URL",
  },

  permissions: {
    ACCESS_ADMIN_PORTAL: "access_admin_portal",
    ACKNOWLEDGE_ECM_NOTIFICATIONS: "acknowledge_ecm_notifications",
    ACKNOWLEDGE_ECM_NOTIFICATIONS_LIMITED:
      "acknowledge_ecm_notifications_limited",
    ADD_INCIDENT_UPDATE: "add_incident_update",
    ADD_INCIDENT_UPDATE_LIMITED: "add_incident_update_limited",
    APPROVAL_OHS: "approval_ohs",
    APPROVAL_OHS_LIMITED: "approval_ohs_limited",
    CHANGE_INCIDENT_STATUS: "change_incident_status",
    CHANGE_INCIDENT_STATUS_LIMITED: "change_incident_status_limited",
    CREATE_BROADCAST: "create_broadcast",
    CREATE_INCIDENT: "create_incident",
    CREATE_INCIDENT_LIMITED: "create_incident_limited",
    CREATE_SITREP: "create_sitrep",
    CREATE_SITREP_LIMITED: "create_sitrep_limited",
    CREATE_WORK_DECLARATION: "create_work_declaration",
    CREATE_WORK_DECLARATION_LIMITED: "create_work_declaration_limited",
    CREATE_OHS_LIMITED: "create_ohs_limited",
    CREATE_OHS: "create_ohs",
    EDIT_INCIDENT: "edit_incident",
    EDIT_INCIDENT_LIMITED: "edit_incident_limited",
    EDIT_SITREP: "edit_sitrep",
    EDIT_SITREP_LIMITED: "edit_sitrep_limited",
    EDIT_USER: "edit_user",
    EDIT_USER_LIMITED: "edit_user_limited",
    EDIT_WORK_DECLARATION: "edit_work_declaration",
    EDIT_WORK_DECLARATION_LIMITED: "edit_work_declaration_limited",
    EDIT_OHS_LIMITED: "edit_ohs_limited",
    EDIT_OHS: "edit_ohs",
    RECEIVE_DRAINS_NOTIFICATIONS: "receive_drains_notifications",
    RECEIVE_DRAINS_NOTIFICATIONS_LIMITED:
      "receive_drains_notifications_limited",
    RECEIVE_ECM_NOTIFICATIONS: "receive_ecm_notifications",
    RECEIVE_ECM_NOTIFICATIONS_LIMITED: "receive_ecm_notifications_limited",
    RECEIVE_INCIDENT_NOTIFICATIONS: "receive_incident_notifications",
    RECEIVE_INCIDENT_NOTIFICATIONS_LIMITED:
      "receive_incident_notifications_limited",
    RECEIVE_SITREP_NOTIFICATIONS: "receive_sitrep_notifications",
    RECEIVE_SITREP_NOTIFICATIONS_LIMITED:
      "receive_sitrep_notifications_limited",
    RECTIFICATION_OHS: "rectifaction_ohs",
    RECTIFICATION_OHS_LIMITED: "rectifaction_ohs_limited",
    VETTER_OHS_LIMITED: "vetter_ohs_limited",
    VETTER_OHS: "vetter_ohs",
    VIEW_CCTV: "view_cctv",
    VIEW_CCTV_LIMITED: "view_cctv_limited",
    VIEW_DASHBOARD: "view_dashboard",
    VIEW_DASHBOARD_LIMITED: "view_dashboard_limited",
    VIEW_DRAINS: "view_drains",
    VIEW_DRAINS_LIMITED: "view_drains_limited",
    VIEW_ECM: "view_ecm",
    VIEW_ECM_LIMITED: "view_ecm_limited",
    VIEW_INBOX: "view_inbox",
    VIEW_INBOX_LIMITED: "view_inbox_limited",
    VIEW_INCIDENT: "view_incident",
    VIEW_INCIDENT_LIMITED: "view_incident_limited",
    VIEW_MAP: "view_map",
    VIEW_MY_PROFILE: "view_my_profile",
    VIEW_SITREP: "view_sitrep",
    VIEW_SITREP_LIMITED: "view_sitrep_limited",
    VIEW_WORK_DECLARATION: "view_work_declaration",
    VIEW_WORK_DECLARATION_LIMITED: "view_work_declaration_limited",
    VIEW_ECM_INCIDENT: "view_ecm_incident",
    VIEW_ECM_INCIDENT_LIMITED: "view_ecm_incident_limited",
    VIEW_INCIDENT_UPDATE: "view_incident_update",
    VIEW_INCIDENT_UPDATE_LIMITED: "view_incident_update_limited",
    VIEW_OHS_LIMITED: "view_ohs_limited",
    VIEW_OHS: "view_ohs",
    WDC_DOWNLOAD_FILE: "access_work_declaration_files",
    OHS_EXPORT_CONTROL_LIMITED: "export_csv_ohs_limited",
    OHS_EXPORT_CONTROL: "export_csv_ohs",
    VIEW_QUICKSIGHT_DASHBOARD: "view_quicksight_dashboard",
  },

  static_url: "https://d2cj8qevuk8el6.cloudfront.net",
  USER_GUIDE_PATH: "pdf/CAG+CEreal+-+User+Guide.pdf",
  S3_URL:
    "https://dev-cag-cereal-backend-091941318471.s3.ap-southeast-1.amazonaws.com",
};

export default config;
