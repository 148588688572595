import { Editor, Frame, Element } from "@craftjs/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Viewport, RenderNode } from "./editor";
import { Container, Text } from "./selectors";
import { Button } from "./selectors/Button";
import { TextField } from "./selectors/TextField";
import { Panel } from "./selectors/Panel";
import { DatePicker } from "./selectors/DatePicker";
import { TimePicker } from "./selectors/TimePicker";
import { RadioField } from "./selectors/RadioField";
import { ComboBoxField } from "./selectors/ComboBoxField";
import { DropdownList } from "./selectors/DropdownList";
import { TextArea } from "./selectors/TextArea";
import { CheckList } from "./selectors/CheckList";
import { MapField } from "./selectors/MapField";
import { AutoUser } from "./selectors/AutoUser";
import "./index.css";
import { CheckListItem } from "./selectors/CheckListItem";
import { FileUpload } from "./selectors/Fileupload";
// import useSelectorRule from "./hooks/useSelectorRule";
import { FormContext } from "../context/form";
import { useMediaQuery } from "react-responsive";
import evaluateVisibility from "./utils/evaluateVisibility";
import evaluateEditability from "./utils/evaluateEditability";
import { workFlow } from "../WorkflowConfig";

const FormDesigner = (props) => {
  const { disabled, tab, tabID, isDetail, isEdit, updateApproverHandler } =
    props;
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isMobile425 = useMediaQuery({ maxWidth: 425 });
  const {
    state,
    recordState,
    configState,
    userPermissions,
    userGroups,
    touchedState,
    approverHandlerState,
  } = useContext(FormContext);
  const [formConfig, setFormConfig] = configState;
  const [approverHandler, setApproverHandler] = approverHandlerState;
  // json is the saved state of the form designer
  const [json] = useState();
  const [values] = state;
  const [recordDetail] = recordState;
  const [touched] = touchedState;

  // Fetch initial form configuration (schema)
  useEffect(() => {
    let init = false;
    const loadConfig = async () => {
      const config = await fetch("/fixtures/getFormConfig.json");
      // Why do you keep disabling this line sithu? Stop it.
      setFormConfig(await config.json());
    };
    if (!init) {
      loadConfig();
      console.log("this is editable Detail", recordDetail, approverHandler);
      console.log(recordDetail);
    }
    return () => {
      init = true;
    };
  }, []);

  // Global form props
  // const _formProps = {
  //   disabled: isDetail === true ? true : isEdit === true ? !isEditable : false,
  //   recordDetail,
  // };

  const checkValidation = (items, id) => {
    if (id === "approval_status 1")
      console.log(id, items, "this is validation check");
    if (items !== undefined || id !== undefined) {
      if (props.tabID === 0) {
        if (props.status && props.status[0] && props.status[0].main_details) {
          // if (props.status[0].main_details.find((i) => i.id === id)) {
          const find = props.status[0].main_details.find((i) => i.id === id);
          if (find) {
            find.value = items;
            props.setStatus([...props.status]);
          } else {
            props.status[0].main_details.push({ id: id, value: items });
          }
          // }
        } else {
          props.status.push({
            main_details: [
              {
                id: id,
                value: items,
              },
            ],
          });
          props.setStatus([...props.status]);
        }
      } else {
        if (
          props.status &&
          props.status[props.tabID] &&
          props.status[props.tabID]?.[`OHS ${props.tabID}`]
        ) {
          const find = props.status[props.tabID]?.[`OHS ${props.tabID}`].find(
            (i) => i.id === id
          );
          if (find) {
            find.value = items;
            props.setStatus([...props.status]);
          } else {
            props.status[props.tabID]?.[`OHS ${props.tabID}`].push({
              id: id,
              value: items,
            });
          }
        } else {
          props.status.push({
            [`OHS ${props.tabID}`]: [
              {
                id: id,
                value: items,
              },
            ],
          });
          props.setStatus([...props.status]);
        }
      }
    }
  };

  // Show tab content based on the active tab.
  // Using useMemo for performance.
  const widgetsToRender = useMemo(() => {
    const { widgets = [] } = formConfig || {};
    let widgetsArray = [];

    // Render widgets for main details tab
    if (tab === "Main Details") {
      widgetsArray = widgets.filter((w) => w.id === "main_details");
    } else {
      // Render widgets for other tabs
      widgetsArray = widgets.filter((w) => w.id !== "main_details");
    }
    let returnWidget = [];
    if ((approverHandler !== null && isDetail) || isEdit) {
      for (var i = 0; i < widgetsArray.length; i++) {
        if (widgetsArray[i].id === "panel-vetter") {
          break;
        } else {
          const visibilityRules = Array.isArray(
            widgetsArray[i]?.visibility?.rules
          )
            ? // advanced visibility rules
              widgetsArray[i].visibility.rules
            : // simple (old) visibility rules
              // TODO: Deprecate old version
              widgetsArray[i].visibility;
          const { isVisible } = evaluateVisibility({
            values,
            visibility: visibilityRules,
            combinator: widgetsArray[i]?.visibility?.combinator,
            recordDetail,
            userPermissions,
            userGroups,
            tabID: tabID,
          });
          console.log("this is approerhandler", approverHandler);
          returnWidget.push({
            ...widgetsArray[i],
            isVisible: isVisible(),
            isEditable:
              approverHandler?.editable?.editable_fields_without_permission?.find(
                (j) => j === widgetsArray[i].id
              )
                ? true
                : false,
          });
        }
      }
      if (tab !== "Main Details") {
        console.log(props.approvalDetail, "aoprovaldetail");
        props.approvalDetail.map((j) => {
          const find = widgetsArray.find(
            (l) => l.id === j.workflow_config.panel_id
          );
          let copy_widget = JSON.parse(JSON.stringify(find));
          let dropdownValue =
            workFlow[j.workflow_config.flow_id].stages?.[
              j.workflow_config.current_stage
            ].editable.render_status_for_approval_dropdown;
          console.log("dropdownvaue", dropdownValue);
          copy_widget.children.map((k) => {
            if (k.key === "approval_status") {
              k.options = dropdownValue;
              k.value = j.workflow_config.status;

              k.isMandaotry = true;
              k.required = false;
            } else {
              console.log(j[j.workflow_config.approval_key]?.[k.key]);
              k.value = j[j.workflow_config.approval_key]?.[k.key];
              if (k.required) k.isMandaotry = true;
              k.required = false;
              k.detail = true;
              k.visibility = false;
              console.log(k);
              if (j[j.workflow_config.approval_key]?.[k.key] !== undefined) {
                k.show = true;
              } else {
                k.show = false;
              }
              // if (
              //   j.workflow_config.panel_id === "panel-vetter" &&
              //   j[j.workflow_config.approval_key]?.[k.key] !== undefined
              // ) {
              //   delete k.visibility;
              // }
              // else {
              //   if (j[j.workflow_config.approval_key]?.[k.key] === undefined) {
              //     k.visibility = false;
              //   } else {
              //     k.visibility = true;
              //   }
              // }
            }
          });
          console.log("difference", copy_widget, find);
          if (find) {
            returnWidget.push({
              ...copy_widget,
              isEditable: false,
              isVisible: true,
            });
          }
        });
      }
      widgetsArray.find((widget) => {
        console.log("this one called", approverHandler);
        if (widget.id === approverHandler?.editable?.panel_id) {
          let tempWidgetEdit = { ...widget };
          tempWidgetEdit.children.find(
            (child) => child.key === "approval_status"
          ).options =
            approverHandler?.editable?.render_status_for_approval_dropdown;
          returnWidget.push({
            ...tempWidgetEdit,
            isEditable: approverHandler?.editable?.editable_fields.find(
              (i) => i === tempWidgetEdit.id
            )
              ? true
              : false,
            isVisible: true,
          });
        }
      });
      console.log(returnWidget, "returnWidget");
      return returnWidget.filter((w) => {
        return w.isVisible;
      });
    } else
      return widgetsArray
        .map((widget) => {
          const visibilityRules = Array.isArray(widget?.visibility?.rules)
            ? // advanced visibility rules
              widget.visibility.rules
            : // simple (old) visibility rules
              // TODO: Deprecate old version
              widget.visibility;
          const { isVisible } = evaluateVisibility({
            values,
            visibility: visibilityRules,
            combinator: widget?.visibility?.combinator,
            recordDetail,
            userPermissions,
            userGroups,
            tabID: tabID,
          });

          const { isEditable } = evaluateEditability({
            values,
            editability: widget?.editability,
            recordDetail,
          });

          return {
            ...widget,
            isVisible: isVisible(),
            isEditable: isEditable(),
          };
        })
        .filter((w) => {
          console.log("filtered", w);
          return w.isVisible;
        });
  }, [tab, formConfig, values, recordDetail, userPermissions, tabID]);

  // Generate unique editor key to avoid unwanted caching
  const editorKey = useMemo(() => {
    const mapUniqueWidget = (w) => `${w.id}:${w.isVisible}`;
    return `${widgetsToRender.map(mapUniqueWidget).join("&")}-${tabID}`;
  }, [widgetsToRender, tabID]);
  console.log("this is formConfig", props.status);
  console.log("this is formConfig", props.WMS);

  const formDisbale = (value) => {
    console.log(value, "this is editable", isDetail, isEdit);
    if (isDetail === true) {
      return true;
    }
    if (isEdit === true) {
      if (value === true) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  return formConfig ? (
    <Editor
      resolver={{
        AutoUser,
        Button,
        CheckList,
        CheckListItem,
        ComboBoxField,
        Container,
        DatePicker,
        DropdownList,
        Panel,
        RadioField,
        Text,
        TextArea,
        TextField,
        TimePicker,
        FileUpload,
        MapField,
      }}
      enabled={false}
      onRender={RenderNode}
      key={editorKey}
    >
      <Viewport touched={touched}>
        <Frame json={json}>
          <Element
            canvas
            is={Container}
            width={isMobile425 ? "90%" : isMobile ? "80%" : "60%"}
            height="auto"
            background={{ r: 255, g: 255, b: 255, a: 1 }}
            custom={{ displayName: "Form Container" }}
          >
            <div className="mb-5 p-2 bg-slate-50 rounded-md w-full box-border font-bold">
              <span className="text-red">*</span>
              <span>Compulsory fields to be filled up prior to submission</span>
            </div>
            {widgetsToRender.map((widget) => {
              // Read field's editability state from the formProps
              // or the set panel's editability rules
              const { isEditable } = widget;
              const isPanelDisabled = !isEditable;
              const formProps = {
                disabled: formDisbale(isEditable),
                recordDetail,
              };
              console.log(
                "this is form Props",
                formProps,
                isDetail,
                isEdit,
                isEditable,
                approverHandler,
                formDisbale(isEditable)
              );
              return (
                <Element
                  canvas
                  is={Panel}
                  width="100%"
                  height="auto"
                  background={{ r: 255, g: 255, b: 255, a: 1 }}
                  custom={{ displayName: "Form Panel" }}
                  text={widget.title}
                  margin={[0, 0, 30, 0]}
                >
                  {(widget?.children || []).map(
                    ({ key, children, ...field }) => {
                      // Map field id from key so that it's not conflicting
                      // with internal react key property
                      field.id = key;

                      switch (field?.type) {
                        case "text":
                          return (
                            <TextField
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "textarea":
                          return (
                            <TextArea
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "user":
                          return (
                            <AutoUser
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "date":
                          return (
                            <DatePicker
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "time":
                          return (
                            <TimePicker
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "radio":
                          return (
                            <RadioField
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "checklist":
                          return (
                            <CheckList
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "checklist-item":
                          return (
                            <CheckListItem
                              tabID={props.tabID}
                              key={key}
                              items={children}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "combobox":
                          return (
                            <ComboBoxField
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "dropdown":
                          return (
                            <DropdownList
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              removeForm={props.removeForm}
                              addForm={props.addForm}
                              deleteForm={props.deleteForm}
                              updateApproverHandler={updateApproverHandler}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "map":
                          return (
                            <MapField
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              mapLayerImage={props.mapLayerImage}
                              isWMS={props.isWMS}
                              WMS={props.WMS}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        case "file":
                          return (
                            <FileUpload
                              checkValidation={checkValidation}
                              tabID={props.tabID}
                              key={key}
                              disabled={formDisbale(isEditable)}
                              recordDetail={recordDetail}
                              {...field}
                            />
                          );
                        default:
                          return <Text key={key} text={field?.label} />;
                      }
                    }
                  )}
                </Element>
              );
            })}
          </Element>
        </Frame>
      </Viewport>
    </Editor>
  ) : null;
};

export default FormDesigner;
