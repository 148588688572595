import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";
import api from "../config/api";
import config from "../config/config";

const { WMS_ENDPOINT } = config.api_endpoint;
const initialState = {
  loading: true,
  WMS: "",
  error: null,
};

export const fetchWMS = createAsyncThunk(
  "wms/fetchWMS",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(WMS_ENDPOINT, {}, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const WMSSlice = createSlice({
  name: "wms",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWMS.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWMS.fulfilled, (state, action) => {
        state.loading = false;
        state.WMS = action.payload.data;
      })
      .addCase(fetchWMS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const WMSReducer = WMSSlice.reducer;
export default WMSReducer;
