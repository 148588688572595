import mapboxgl, { Marker } from "mapbox-gl";
import NoImg from "../../assets/images/Map/no_image.png";
import MenuIcon from "../../assets/images/Map/menu_icon.svg";
import Acident from "../../assets/images/Map/incident_popup.svg";
import FavIcon from "../../assets/images/Map/fav_icon.svg";
import MarkerIcon from "../../assets/images/Map/annotationIcon/Marker.svg";
import MapCCTV from "../../assets/images/Icon/Map_CCTV.svg";
import MapIncident from "../../assets/images/Icon/Map_incident.svg";
import FavouriteLogo from "../../assets/images/cctv/favourite.svg";
import UnFavouriteLogo from "../../assets/images/cctv/unfavourite.svg";
// import IncidentWithBorder from "../../assets/images/Map/Incident_with_yellow_border.svg";
import WorkWithBorder from "../../assets/images/Map/Work_with_border.svg";
import config from "../../config/config";
import api from "../../config/api";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import { SVY21 } from "./SVY21";
import SiteUtilisation from "./RW_CAG_CN_NAV_PMG_A19_SP-1001AQ_WS SVY21]_CEREAL_231024 (3).png";

let Marker_data = [];
let Marker_temp = [];
let Fav_item = [];
let MarkerAll = [];
let Geo = [];
let Utilisation_data = [];
const RandomizeId1 = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  ",",
  ".",
  ":",
  ";",
];

export const filterMarker = (local, api_data, maps, Id, FilterSingle) => {
  if (Array.isArray(local)) {
    for (let i = 0; i < local.length + 1; i++) {
      if (i < local.length) {
        // local.map((i) => {
        const ApiDataFind = api_data.find((a) => a.name === local[i].name);
        console.log(api_data, local[i]);
        if (ApiDataFind) {
          if (ApiDataFind.name === "CCTV") {
            ApiDataFind.features.map((j) => {
              if (local[i].list.length > 0) {
                const list = local[i].list.find((k) => k === "All");
                if (!list) {
                  if ("field" in local[i]) {
                    const list1 = local[i].list.find(
                      (w) => w === j[local[i]["field"]]
                    );
                    if (list1) {
                      j.icon = MapCCTV;
                      Marker_data.push(j);
                    }
                  } else {
                    const list1 = local[i].list.find((w) => w === j.package);
                    if (list1) {
                      j.icon = MapCCTV;
                      Marker_data.push(j);
                    }
                  }
                } else {
                  j.icon = MapCCTV;
                  Marker_data.push(j);
                }
              } else if (local[i].list.length === 0) {
                j.icon = MapCCTV;
                Marker_data.push(j);
              }
            });
          } else if (ApiDataFind.name === "Incident_case") {
            ApiDataFind.features.map((j) => {
              if (!Marker_data.find((data) => data.id === j.id)) {
                if (local[i].list.length > 0) {
                  const list = local[i].list.find((k) => k === "All");
                  if (!list) {
                    if ("field" in local[i]) {
                      const list1 = local[i].list.find(
                        (w) => w === j[local[i]["field"]]
                      );
                      if (list1) {
                        Marker_data.push(j);
                      }
                    } else {
                      const list1 = local[i].list.find((w) => w === j.package);
                      if (list1) {
                        Marker_data.push(j);
                      }
                    }
                  } else {
                    Marker_data.push(j);
                  }
                } else if (local[i].list.length === 0) {
                  Marker_data.push(j);
                }
              } else {
                if (!Marker_data.find((data) => data.map_id === j.map_id)) {
                  if (local[i].list.length > 0) {
                    const list = local[i].list.find((k) => k === "All");
                    if (!list) {
                      if ("field" in local[i]) {
                        const list1 = local[i].list.find(
                          (w) => w === j[local[i]["field"]]
                        );
                        if (list1) {
                          Marker_data.push(j);
                        }
                      } else {
                        const list1 = local[i].list.find(
                          (w) => w === j.package
                        );
                        if (list1) {
                          Marker_data.push(j);
                        }
                      }
                    } else {
                      Marker_data.push(j);
                    }
                  } else if (local[i].list.length === 0) {
                    Marker_data.push(j);
                  }
                }
              }
            });
          } else if (ApiDataFind.name === "Work_declaration") {
            ApiDataFind.features.map((j) => {
              if (!Marker_data.find((data) => data.id === j.id)) {
                if (local[i].list.length > 0) {
                  const list = local[i].list.find((k) => k === "All");
                  if (!list) {
                    if ("field" in local[i]) {
                      const list1 = local[i].list.find(
                        (w) => w === j[local[i]["field"]]
                      );
                      if (list1) {
                        Marker_data.push(j);
                      }
                    } else {
                      const list1 = local[i].list.find((w) => w === j.package);
                      if (list1) {
                        Marker_data.push(j);
                      }
                    }
                  } else {
                    Marker_data.push(j);
                  }
                } else if (local[i].list.length === 0) {
                  Marker_data.push(j);
                }
              } else {
                if (!Marker_data.find((data) => data.map_id === j.map_id)) {
                  if (local[i].list.length > 0) {
                    const list = local[i].list.find((k) => k === "All");
                    if (!list) {
                      if ("field" in local[i]) {
                        const list1 = local[i].list.find(
                          (w) => w === j[local[i]["field"]]
                        );
                        if (list1) {
                          Marker_data.push(j);
                        }
                      } else {
                        const list1 = local[i].list.find(
                          (w) => w === j.package
                        );
                        if (list1) {
                          Marker_data.push(j);
                        }
                      }
                    } else {
                      Marker_data.push(j);
                    }
                  } else if (local[i].list.length === 0) {
                    Marker_data.push(j);
                  }
                }
              }
            });
          } else if (ApiDataFind.name === "ECM_drain") {
            ApiDataFind.features.map((j) => {
              if (!Marker_data.find((data) => data.id === j.id)) {
                if (local[i].list.length > 0) {
                  const list = local[i].list.find((k) => k === "All");
                  if (!list) {
                    if ("field" in local[i]) {
                      const list1 = local[i].list.find(
                        (w) => w === j[local[i]["field"]]
                      );
                      if (list1) {
                        Marker_data.push(j);
                      }
                    } else {
                      const list1 = local[i].list.find((w) => w === j.package);
                      if (list1) {
                        Marker_data.push(j);
                      }
                    }
                  } else {
                    Marker_data.push(j);
                  }
                } else if (local[i].list.length === 0) {
                  Marker_data.push(j);
                }
              }
            });
          } else if (ApiDataFind.name === "Lamp_post") {
            ApiDataFind.features.map((j) => {
              if (!Marker_data.find((data) => data.id === j.id)) {
                if (local[i].list.length > 0) {
                  const list = local[i].list.find((k) => k === "All");
                  if (!list) {
                    if ("field" in local[i]) {
                      const list1 = local[i].list.find(
                        (w) => w === j[local[i]["field"]]
                      );
                      if (list1) {
                        Marker_data.push(j);
                      }
                    } else {
                      const list1 = local[i].list.find((w) => w === j.package);
                      if (list1) {
                        Marker_data.push(j);
                      }
                    }
                  } else {
                    Marker_data.push(j);
                  }
                } else if (local[i].list.length === 0) {
                  Marker_data.push(j);
                }
              }
            });
          }
        }
      } else {
        addMarkerOnMap(maps, Id);
      }
      // });
    }
  } else {
    const api = api_data.find((da1) => da1.name === "CCTV");
    api.features.map((data1) => {
      if (data1.camera_no === local) {
        Marker_data.push(data1);
      }
    });
    addMarkerOnMap(maps, Id);
  }
};

const FAVOURITE_FILTER = (local, api_data, maps) => {
  let favCCTVs = localStorage.getItem("favourite_cctvs")
    ? localStorage.getItem("favourite_cctvs").split(",")
    : [];
  let favECMs = localStorage.getItem("ECMDrain_favourite")
    ? localStorage.getItem("ECMDrain_favourite").split(",")
    : [];

  for (let i = 0; i < local.length; i++) {
    if (local[i].name === "Favourite") {
      Fav_item.push({
        name: "CCTV",
        data: favCCTVs,
      });
      Fav_item.push({
        name: "ECM_drain",
        data: favECMs,
      });
    }
    if (local[i].name === "CCTV") {
      const local_data = local[i];
      const APIDATAFIND = api_data.find((data) => data.name === "CCTV");
      if (APIDATAFIND) {
        APIDATAFIND.features.map((j) => {
          j.type = "cctv";
          if (!Marker_data.find((da) => da.id === j.id)) {
            if (local_data.list.length > 0) {
              const list = local_data.list.find((k) => k === "All");
              if (!list) {
                if ("field" in local_data) {
                  const list1 = local_data.list.find(
                    (w) => w === j[local_data["field"]]
                  );
                  if (list1) {
                    j.icon = MapCCTV;
                    Marker_data.push(j);
                  }
                } else {
                  const list1 = local_data.list.find((w) => w === j.package);
                  if (list1) {
                    j.icon = MapCCTV;
                    Marker_data.push(j);
                  }
                }
              } else {
                j.icon = MapCCTV;
                Marker_data.push(j);
              }
            } else if (local_data.list.length === 0) {
              j.icon = MapCCTV;
              Marker_data.push(j);
            }
          }
        });
      }
    }
    if (local[i].name === "Incident_case") {
      const local_data = local[i];
      const APIDATAFIND = api_data.find(
        (data) => data.name === "Incident_case"
      );
      if (APIDATAFIND) {
        APIDATAFIND.features.map((j) => {
          if (!Marker_data.find((data) => data.id === j.id)) {
            if (local_data.list.length > 0) {
              const list = local_data.list.find((k) => k === "All");
              if (!list) {
                if ("field" in local_data) {
                  const list1 = local_data.list.find(
                    (w) => w === j[local_data["field"]]
                  );
                  if (list1) {
                    Marker_data.push(j);
                  }
                } else {
                  const list1 = local_data.list.find((w) => w === j.package);
                  if (list1) {
                    Marker_data.push(j);
                  }
                }
              } else {
                Marker_data.push(j);
              }
            } else if (local_data.list.length === 0) {
              Marker_data.push(j);
            }
          } else {
            if (!Marker_data.find((data) => data.map_id === j.map_id)) {
              if (local_data.list.length > 0) {
                const list = local_data.list.find((k) => k === "All");
                if (!list) {
                  if ("field" in local_data) {
                    const list1 = local_data.list.find(
                      (w) => w === j[local_data["field"]]
                    );
                    if (list1) {
                      Marker_data.push(j);
                    }
                  } else {
                    const list1 = local_data.list.find((w) => w === j.package);
                    if (list1) {
                      Marker_data.push(j);
                    }
                  }
                } else {
                  Marker_data.push(j);
                }
              } else if (local_data.list.length === 0) {
                Marker_data.push(j);
              }
            }
          }
        });
      }
    }
    if (local[i].name === "Work_declaration") {
      const local_data = local[i];
      const APIDATAFIND = api_data.find(
        (data) => data.name === "Work_declaration"
      );
      if (APIDATAFIND) {
        APIDATAFIND.features.map((j) => {
          if (!Marker_data.find((data) => data.id === j.id)) {
            if (local_data.list.length > 0) {
              const list = local_data.list.find((k) => k === "All");
              if (!list) {
                if ("field" in local_data) {
                  const list1 = local_data.list.find(
                    (w) => w === j[local_data["field"]]
                  );
                  if (list1) {
                    Marker_data.push(j);
                  }
                } else {
                  const list1 = local_data.list.find((w) => w === j.package);
                  if (list1) {
                    Marker_data.push(j);
                  }
                }
              } else {
                Marker_data.push(j);
              }
            } else if (local_data.list.length === 0) {
              Marker_data.push(j);
            }
          } else {
            if (!Marker_data.find((data) => data.map_id === j.map_id)) {
              if (local_data.list.length > 0) {
                const list = local_data.list.find((k) => k === "All");
                if (!list) {
                  if ("field" in local_data) {
                    const list1 = local_data.list.find(
                      (w) => w === j[local_data["field"]]
                    );
                    if (list1) {
                      Marker_data.push(j);
                    }
                  } else {
                    const list1 = local_data.list.find((w) => w === j.package);
                    if (list1) {
                      Marker_data.push(j);
                    }
                  }
                } else {
                  Marker_data.push(j);
                }
              } else if (local_data.list.length === 0) {
                Marker_data.push(j);
              }
            }
          }
        });
      }
    }
    if (local[i].name === "ECM_drain") {
      const local_data = local[i];
      const APIDATAFIND = api_data.find((data) => data.name === "ECM_drain");
      if (APIDATAFIND) {
        APIDATAFIND.features.map((j) => {
          j.type = "ecm";
          if (!Marker_data.find((data) => data.id === j.id)) {
            if (local_data.list.length > 0) {
              const list = local_data.list.find((k) => k === "All");
              if (!list) {
                if ("field" in local_data) {
                  const list1 = local_data.list.find(
                    (w) => w === j[local_data["field"]]
                  );
                  if (list1) {
                    Marker_data.push(j);
                  }
                } else {
                  const list1 = local_data.list.find((w) => w === j.package);
                  if (list1) {
                    Marker_data.push(j);
                  }
                }
              } else {
                Marker_data.push(j);
              }
            } else if (local_data.list.length === 0) {
              Marker_data.push(j);
            }
          }
        });
      }
    }
    if (local[i].name === "Lamp_post") {
      const local_data = local[i];
      const APIDATAFIND = api_data.find((data) => data.name === "Lamp_post");
      if (APIDATAFIND) {
        APIDATAFIND.features.map((j) => {
          if (!Marker_data.find((data) => data.id === j.id)) {
            if (local_data.list.length > 0) {
              const list = local_data.list.find((k) => k === "All");
              if (!list) {
                if ("field" in local_data) {
                  const list1 = local_data.list.find(
                    (w) => w === j[local_data["field"]]
                  );
                  if (list1) {
                    Marker_data.push(j);
                  }
                } else {
                  const list1 = local_data.list.find((w) => w === j.package);
                  if (list1) {
                    Marker_data.push(j);
                  }
                }
              } else {
                Marker_data.push(j);
              }
            } else if (local_data.list.length === 0) {
              Marker_data.push(j);
            }
          }
        });
      }
    }
  }
  for (let i = 0; i < Fav_item.length + 1; i++) {
    if (i < Fav_item.length) {
      const api_data_search = api_data.find(
        (da) => da.name === Fav_item[i].name
      );
      if (api_data_search) {
        Fav_item[i].data.map((l) => {
          api_data_search.features.map((j) => {
            if (
              Fav_item[i].name === "CCTV"
                ? j.camera_no === l
                : j.Uti_no === l && favCCTVs.indexOf(l) > -1
            ) {
              j.icon = FavIcon;
              Marker_data.push(j);
            } else if (
              Fav_item[i].name === "ECM_drain"
                ? j.ecm_no === l
                : j.Uti_no === l && favECMs.indexOf(l) > -1
            ) {
              j.icon = FavIcon;
              Marker_data.push(j);
            }
          });
        });
      }
    }
  }
};

export const setMarkerOnMap = (data, maps, filter, Id) => {
  Marker_data = [];
  Fav_item = [];
  RemoveMarker();
  if (Array.isArray(filter)) {
    const find_fav = filter.find((da) => da.name === "Favourite");
    if (find_fav) {
      FAVOURITE_FILTER(filter, data, maps, Id);
      addMarkerOnMap(maps, Id);
    } else {
      console.log("doing");
      filterMarker(filter, data, maps, Id);
    }
  } else {
    console.log("doing");
    filterMarker(filter, data, maps, Id);
  }
};

export const addMarkerOnMap = (maps, data) => {
  for (const feature of Marker_data) {
    const popupContent = document.createElement("div");
    popupContent.className = "marker";
    let icon = `url(${feature.icon})`;
    if (data === feature.id) {
      if (feature.layer === "Incident") {
        // icon = `url(${IncidentWithBorder})`;
      } else if (feature.layer === "Work Declaration") {
        console.log("work");
        icon = `url(${WorkWithBorder})`;
      }
    }
    popupContent.style.backgroundImage = icon;
    if (data === feature.id) {
      if (
        feature.layer === "Incident" ||
        feature.layer === "Work Declaration"
      ) {
        popupContent.style.width = "46px";
        popupContent.style.height = "64px";
      } else {
        popupContent.style.height = "30px";
        popupContent.style.width = "21px";
      }
    } else {
      popupContent.style.height = "30px";
      popupContent.style.width = "21px";
    }
    popupContent.style.backgroundSize = "100%";
    const CCTV_div = document.createElement("div");
    if (feature.is_fav === true) {
      CCTV_div.innerHTML = `<div><div><div class=Map_popup_chips>CCTV</div><div class=Map_popup_img_box><img src=${
        feature.img === "" ? NoImg : feature.img
      } class=${
        feature.img === "" ? "Map_popup_img" : "map_popup_have_img"
      } /></div></div><div class=Map_popup_detail_box><div class=Map_popup_detail_text>${
        feature.camera_no
      }</div><div class=Map_popup_detail_text1>${
        feature.camera_name
      }</div></div><div class=Map_popup_footer>
      <button class=button_map id=menuOnClick name=${
        feature.id
      }><img src=${MenuIcon} id="${feature.id}" /></button>
      <button class=button_map ><img src=${Acident} /></button>
      <button class="button_map unFavOnClick" data-type=${
        feature.type
      } data-id="${
        feature.id
      }"><img src=${FavouriteLogo} class="unFavOnClick" data-type=${
        feature.type
      } data-id="${feature.id}" /></button></div></div>`;
    } else {
      CCTV_div.innerHTML = `<div><div><div class=Map_popup_chips>CCTV</div><div class=Map_popup_img_box><img src=${
        feature.img === "" ? NoImg : feature.img
      } class=${
        feature.img === "" ? "Map_popup_img" : "map_popup_have_img"
      } /></div></div><div class=Map_popup_detail_box><div class=Map_popup_detail_text>${
        feature.camera_no
      }</div><div class=Map_popup_detail_text1>${
        feature.camera_name
      }</div></div><div class=Map_popup_footer>
      <button class=button_map id=menuOnClick name=${
        feature.id
      }><img src=${MenuIcon} id="${feature.id}" /></button>
      <button class=button_map ><img src=${Acident} /></button>
      <button class="button_map favOnClick" data-type=${
        feature.type
      } data-id="${
        feature.id
      }"><img src=${UnFavouriteLogo} class="favOnClick" data-type=${
        feature.type
      } data-id="${feature.id}" /></button></div></div>`;
    }

    const Incident_div = document.createElement("div");
    Incident_div.innerHTML = `<div>
    <div class=Map_popup_incident_main_box>
    <div class=Map_popup_incident_title>${feature.title}</div>
    <div class=Map_popup_incident_sec_box>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Date of Incident</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_date}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Location</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_location}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Classification of Incident</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_type}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Package</div>
    <div class=Map_popup_incident_sec_data>${feature.package}</div>
    </div>
    </div>
    </div>
    <div class=Map_popup_footer><button class=button_map id=IncidentOnClick name=${feature.id} ><div class=Map_popup_MoreDeatail id="${feature.id}">Click here for more details </div></button></div>
    </div>`;
    const Work_div = document.createElement("div");
    Work_div.innerHTML = `<div>
    <div class=Map_popup_incident_main_box>
    <div class=Map_popup_incident_title>${feature.title}</div>
    <div class=Map_popup_incident_sec_box>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Package</div>
    <div class=Map_popup_incident_sec_data>${feature.package}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Location</div>
    <div class=Map_popup_incident_sec_data>${feature.work_location}</div>
    </div>
    <div class=Map_popup_incident_80W>
    <div class=Map_popup_incident_sec_title>Work activities</div>
    <div class=Map_popup_incident_sec_data>${feature.work_activity}</div>
    </div>
    </div>
    </div>
    <div class=Map_popup_footer><button class=button_map id=WorkOnClick name=${feature.id} ><div class=Map_popup_MoreDeatail id="${feature.id}">Click here for more details </div></button></div>
    </div>`;
    const ECMDrain_div = document.createElement("div");
    if (feature.is_fav === true) {
      ECMDrain_div.innerHTML = `<div><div><div class=Map_popup_chips style="background-color: #52B5DF">ECM & drain</div><div class=Map_popup_img_box><img src=${
        feature.img === "" ? NoImg : feature.img
      } class=${
        feature.img === "" ? "Map_popup_img" : "map_popup_have_img"
      } /></div></div><div class=Map_popup_detail_box><div class=Map_popup_detail_text>${
        feature.ecm_no
      }</div><div class=Map_popup_detail_text1>${
        feature.ecm_name
      }</div><div class=Map_popup_detail_text2><span style="color: rgb(82, 200, 101)">●</span> ${
        feature.tss_value
      } ${
        feature.tss_unit
      }</div></div><div class=Map_popup_footer><button class=button_map id=menuOnClick name=${
        feature.id
      }><img src=${MenuIcon} id="${
        feature.id
      }" /></button><button class=button_map ><img src=${Acident} /></button>
      <button class=button_map class="button_map unFavOnClick" data-type=${
        feature.type
      } data-id="${
        feature.id
      }"><img src=${FavouriteLogo} class="unFavOnClick" data-type=${
        feature.type
      } data-id="${feature.id}" /></button></div></div>`;
    } else {
      ECMDrain_div.innerHTML = `<div><div><div class=Map_popup_chips style="background-color: #52B5DF">ECM & drain</div><div class=Map_popup_img_box><img src=${
        feature.img === "" ? NoImg : feature.img
      } class=${
        feature.img === "" ? "Map_popup_img" : "map_popup_have_img"
      } /></div></div><div class=Map_popup_detail_box><div class=Map_popup_detail_text>${
        feature.ecm_no
      }</div><div class=Map_popup_detail_text1>${
        feature.ecm_name
      }</div><div class=Map_popup_detail_text2><span style="color: rgb(82, 200, 101)">●</span> ${
        feature.tss_value
      } ${
        feature.tss_unit
      }</div></div><div class=Map_popup_footer><button class=button_map id=menuOnClick name=${
        feature.id
      }><img src=${MenuIcon} id="${
        feature.id
      }" /></button><button class=button_map ><img src=${Acident} /></button>
      <button class="button_map favOnClick" data-type=${
        feature.type
      } data-id="${feature.id}"  name=${
        feature.id
      }><img src=${UnFavouriteLogo} class="favOnClick" data-type=${
        feature.type
      } data-id="${feature.id}" /></button></div></div>`;
    }
    const Lamp_div = document.createElement("div");
    Lamp_div.innerHTML = `<div>
    <div class=Map_popup_incident_main_box>
    <div class=Map_popup_incident_title>${feature.title}</div>
    </div>`;
    const main_div =
      feature.layer === "CCTV"
        ? CCTV_div
        : feature.layer === "Incident"
        ? Incident_div
        : feature.layer === "Lamp_post"
        ? Lamp_div
        : feature.layer === "Work Declaration"
        ? Work_div
        : ECMDrain_div;
    const marker = new mapboxgl.Marker(popupContent)
      .setLngLat(feature.geometry.coordinates)
      .setPopup(new mapboxgl.Popup({ offset: 26 }).setDOMContent(main_div))
      .addTo(maps);
    Marker_temp.push(marker);
  }
};

export const RemoveMarker = (id) => {
  Marker_temp.forEach((marker) => {
    marker.remove();
  });
  Marker_temp = [];
};

export const DrawPolygon = (map, draw, data) => {
  Geo = data;
  map.on("load", () => {
    map.addSource("areas", {
      type: "geojson",
      data: data,
      generateId: true,
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
      id: "areas",
      type: "fill",
      source: "areas", // reference the data source
      sourceLayer: "original",
      layout: {},
      paint: {
        "fill-color": "#0080ff", // blue color fill
        "fill-opacity": 0.5,
      },
    });
    map.addSource("maine", {
      type: "geojson",
      data: data,
      generateId: true,
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
      id: "maine",
      type: "fill",
      source: "maine", // reference the data source
      sourceLayer: "original",
      layout: {},
      paint: {
        "fill-color": "#000000", // blue color fill
        "fill-opacity": 0,
      },
    });
  });
};

export const SetHightLight = (map, data, all_data) => {
  const find = all_data.features.find((da) => da.id === data.id);
  const notFind = all_data.features.filter((da) => da.id !== data.id);
  map.removeLayer("areas");
  map.removeSource("areas");
  map.removeLayer("maine");
  map.removeSource("maine");
  HighLight(map, find);
  NotHighLight(map, notFind);
};

export const NotHighLight = (map, data) => {
  const new1 = {
    type: "FeatureCollection",
    features: data,
  };
  map.addSource("areas", {
    type: "geojson",
    data: new1,
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: "areas",
    type: "fill",
    source: "areas", // reference the data source
    layout: {},
    paint: {
      "fill-color": "#0080ff", // blue color fill
      "fill-opacity": 0.5,
    },
  });
};

export const HighLight = (map, data) => {
  const highlight = {
    type: "FeatureCollection",
    features: [data],
  };
  map.addSource("maine", {
    type: "geojson",
    data: highlight,
    generateId: true,
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: "maine",
    type: "fill",
    source: "maine", // reference the data source
    layout: {},
    paint: {
      "fill-color": "#000000", // blue color fill
      "fill-opacity": 0.5,
    },
  });
};

export const DeleteItems = (map, data, annotation, marker) => {
  const filter = Geo.features.filter((da) => da.id !== data.id);
  Geo = {
    type: "FeatureCollection",
    features: filter,
  };
  map.removeLayer("areas");
  map.removeSource("areas");
  map.removeLayer("maine");
  map.removeSource("maine");
  NotHighLight(map, filter);
  map.addSource("maine", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: "maine",
    type: "fill",
    source: "maine", // reference the data source
    layout: {},
    paint: {
      "fill-color": "#000000", // blue color fill
      "fill-opacity": 0.5,
    },
  });
  const api_data = {
    markup: [
      {
        PolygonData: Geo,
        Marker: marker,
      },
    ],
  };
  DeleteInAPI(api_data);
};

export const DeleteInAPI = async (data) => {
  const token = await getCurrentLoggedInSessionTokenWithPromise();
  api.put("/api-markup/markup/", JSON.stringify(data), token);
};

export const setMarkerFromData = (
  map,
  name,
  lng,
  lat,
  id,
  action,
  setActiveBg
) => {
  const icon = document.createElement("div");
  icon.style.backgroundImage = `url(${MarkerIcon})`;
  icon.className = "marker";
  icon.style.width = "34px";
  icon.style.height = "40px";
  icon.style.backgroundSize = "100%";
  const marker = new mapboxgl.Marker(icon);
  setActiveBg(false);
  map.getCanvas().style.cursor = "";
  map.off("click", action);
  return {
    id: id,
    name: name,
    marker: marker,
    Lng: lng,
    Lat: lat,
    map: map,
    mapboxgl: new mapboxgl.Popup({ offset: 26 }),
  };
};

export const MultiPinDisabler = (map, action) => {
  map.getCanvas().style.cursor = `url(${MarkerIcon}) 19 50, auto`;
  map.on("click", action);
};

export const RemoveMarkermulti = (id, Marker) => {
  Marker.forEach((marker) => {
    if (marker.id === id) {
      marker.marker.remove();
    }
  });
  return Marker.filter((da) => da.id !== id);
};

export const AddMarker = (map, data, action, setActiveBg) => {
  return setMarkerFromData(
    map,
    data.name,
    data.Lng,
    data.Lat,
    data.id,
    action,
    setActiveBg
  );
};

export const MultiPinPageLoadDetail = (map, data) => {
  console.log(data);
  data.map((i) => {
    const icon = document.createElement("div");
    icon.style.backgroundImage = `url(${MarkerIcon})`;
    icon.className = "marker";
    icon.style.width = "34px";
    icon.style.height = "40px";
    icon.style.backgroundSize = "100%";
    new mapboxgl.Marker(icon).setLngLat([i.Lng, i.Lat]).addTo(map);
  });
};

export const Utilisation_plan_ = (data, map, filter, edit) => {
  Utilisation_data = [];
  const find = filter.find((data) => data.name === "Utilisation_plan");
  if (find) {
    if (edit) {
      if (map.getLayer("areas1")) {
        map.removeLayer("areas1");
      }
      if (map.getLayer("label-style")) {
        map.removeLayer("label-style");
      }
      if (map.getSource("areas1")) {
        map.removeSource("areas1");
      }
      const isAll = find.list.find((data) => data === "All");
      if (isAll) {
        Utilisation_data = data;
      } else {
        if (find.list.length > 0) {
          data.map((da) => {
            if (da.properties.Package == " ") {
              const others = find.list.find((data) => data === "Others");
              if (others) {
                Utilisation_data.push(da);
              }
            } else {
              const Found_data = find.list.find(
                (data) => data === da.properties.Package
              );
              if (Found_data) {
                Utilisation_data.push(da);
              }
            }
          });
        } else {
          Utilisation_data = data;
        }
      }
      DrawUtilisation_plan(data, map, filter, edit);
    } else {
      const isAll = find.list.find((data) => data === "All");
      if (isAll) {
        Utilisation_data = data;
      } else {
        if (find.list.length > 0) {
          data.map((da) => {
            if (da.properties.Package == " ") {
              const others = find.list.find((data) => data === "Others");
              if (others) {
                Utilisation_data.push(da);
              }
            } else {
              const Found_data = find.list.find(
                (data) => data === da.properties.Package
              );
              if (Found_data) {
                Utilisation_data.push(da);
              }
            }
          });
        } else {
          Utilisation_data = data;
        }
      }
      DrawUtilisation_plan(data, map, filter, edit);
    }
  } else {
    if (map.getLayer("areas1")) {
      map.removeLayer("areas1");
    }
    if (map.getLayer("label-style")) {
      map.removeLayer("label-style");
    }
    if (map.getSource("areas1")) {
      map.removeSource("areas1");
    }
  }
};

const DrawUtilisation_plan = (data, map, filter, TF) => {
  if (TF === false) {
    map.on("load", () => {
      map.addSource("areas1", {
        type: "geojson",
        data: {
          features: Utilisation_data,
          type: "FeatureCollection",
        },
        generateId: true,
      });

      // Add a new layer to visualize the polygon.
      map.addLayer({
        id: "areas1",
        type: "fill",
        source: "areas1", // reference the data source
        sourceLayer: "original",
        layout: {},
        paint: {
          "fill-color": "#910A9A", // blue color fill
          "fill-opacity": 0.5,
        },
      });
      map.addLayer({
        id: "label-style",
        type: "symbol",
        source: "areas1",
        layout: {
          "text-field": ["get", "Package"],
        },
        paint: {
          "text-color": "#FFFFFF",
        },
      });
    });
  }
  if (TF === true) {
    map.addSource("areas1", {
      type: "geojson",
      data: {
        features: Utilisation_data,
        type: "FeatureCollection",
      },
      generateId: true,
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
      id: "areas1",
      type: "fill",
      source: "areas1", // reference the data source
      sourceLayer: "original",
      layout: {},
      paint: {
        "fill-color": "#910A9A", // blue color fill
        "fill-opacity": 0.5,
      },
    });
    map.addLayer({
      id: "label-style",
      type: "symbol",
      source: "areas1",
      layout: {
        "text-field": ["get", "Package"],
      },
      paint: {
        "text-color": "#FFFFFF",
      },
    });
  }
};

export const Package_plan_ = (map, filter, load) => {};

export const Height_plan = (map, filter, load, HeightData) => {
  if (load) {
    if (filter.find((da) => da.name === "Height_plan")) {
      map.on("load", () => {
        map.addSource("route1", {
          type: "geojson",
          data: {
            features: HeightData[0].features,
            type: "FeatureCollection",
          },
        });
        map.addLayer({
          id: "route1",
          type: "line",
          source: "route1",
          paint: {
            "line-color": ["get", "Color"],
            "line-width": 2,
          },
        });
      });
      // }
    }
  } else {
    if (map.getLayer("route1")) {
      map.removeLayer("route1");
    }
    if (map.getSource("route1")) {
      map.removeSource("route1");
    }
    if (filter.find((da) => da.name === "Height_plan")) {
      map.addSource("route1", {
        type: "geojson",
        data: {
          features: HeightData[0].features,
          type: "FeatureCollection",
        },
      });
      map.addLayer({
        id: "route1",
        type: "line",
        source: "route1",
        paint: {
          "line-color": ["get", "Color"],
          "line-width": 2,
        },
      });
    }
  }
  // }
};

export const Site_Utilisation_plan = (map, filter, load, data, wdc_case) => {
  console.log(data, filter);
  // return;
  if (load) {
    if (wdc_case) {
      map.on("load", function () {
        data.map((i) => {
          map.addSource(`${i.id}_image`, {
            type: "image",
            // url:
            //   config.static_url +
            //   "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
            // // url: SiteUtilisation,
            // coordinates: [
            //   [103.97238513547705, 1.31906072285895],
            //   [103.99692457768754, 1.3775179812094223],
            //   [104.03453920424442, 1.3611841571367904],
            //   [104.0098499234432, 1.3027298026447482],
            // ],
            url: i.image_url,
            coordinates: [
              [
                JSON.parse(i.corner_points[0][1]),
                JSON.parse(i.corner_points[0][0]),
              ],
              [
                JSON.parse(i.corner_points[1][1]),
                JSON.parse(i.corner_points[1][0]),
              ],
              [
                JSON.parse(i.corner_points[2][1]),
                JSON.parse(i.corner_points[2][0]),
              ],
              [
                JSON.parse(i.corner_points[3][1]),
                JSON.parse(i.corner_points[3][0]),
              ],
            ],
          });

          map.addLayer({
            id: `overlay_${i.id}`,
            source: `${i.id}_image`,
            type: "raster",
            paint: {
              "raster-opacity": 1,
            },
          });
        });
      });
    } else {
      if (filter.find((da) => da.name === "Satilite_plan")) {
        const SatiliteFilter = filter.find((i) => i.name === "Satilite_plan");
        const dataToShow =
          data.features &&
          data.features.find((i) => i.id === SatiliteFilter.list[0]);
        if (SatiliteFilter.list.length > 0) {
          map.on("load", function () {
            data.features &&
              data.features.map((i) => {
                const find = SatiliteFilter.list.find((j) => j === i.id);
                if (find) {
                  map.addSource(`${i.id}_image`, {
                    type: "image",
                    // url:
                    //   config.static_url +
                    //   "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
                    // coordinates: [
                    //   [103.97238513547705, 1.31906072285895],
                    //   [103.99692457768754, 1.3775179812094223],
                    //   [104.03453920424442, 1.3611841571367904],
                    //   [104.0098499234432, 1.3027298026447482],
                    // ],
                    url: i.image_url,
                    coordinates: [
                      [
                        JSON.parse(i.corner_points[0][1]),
                        JSON.parse(i.corner_points[0][0]),
                      ],
                      [
                        JSON.parse(i.corner_points[1][1]),
                        JSON.parse(i.corner_points[1][0]),
                      ],
                      [
                        JSON.parse(i.corner_points[2][1]),
                        JSON.parse(i.corner_points[2][0]),
                      ],
                      [
                        JSON.parse(i.corner_points[3][1]),
                        JSON.parse(i.corner_points[3][0]),
                      ],
                    ],
                  });

                  map.addLayer({
                    id: `overlay_${i.id}`,
                    source: `${i.id}_image`,
                    type: "raster",
                    paint: {
                      "raster-opacity": 1,
                    },
                  });
                }
              });
          });
        }
      }

      // else {
      //   if (wdc_case) {
      //     console.log(data);
      //     map.on("load", function () {
      //       map.addSource("myImageSource", {
      //         type: "image",
      //         // url:
      //         //   config.static_url +
      //         //   "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
      //         // // url: SiteUtilisation,
      //         // coordinates: [
      //         //   [103.97238513547705, 1.31906072285895],
      //         //   [103.99692457768754, 1.3775179812094223],
      //         //   [104.03453920424442, 1.3611841571367904],
      //         //   [104.0098499234432, 1.3027298026447482],
      //         // ],
      //         url: data[0].image_url,
      //         coordinates: [
      //           [
      //             JSON.parse(data[0].corner_points[0][1]),
      //             JSON.parse(data[0].corner_points[0][0]),
      //           ],
      //           [
      //             JSON.parse(data[0].corner_points[1][1]),
      //             JSON.parse(data[0].corner_points[1][0]),
      //           ],
      //           [
      //             JSON.parse(data[0].corner_points[2][1]),
      //             JSON.parse(data[0].corner_points[2][0]),
      //           ],
      //           [
      //             JSON.parse(data[0].corner_points[3][1]),
      //             JSON.parse(data[0].corner_points[3][0]),
      //           ],
      //         ],
      //       });

      //       map.addLayer({
      //         id: "overlay",
      //         source: "myImageSource",
      //         type: "raster",
      //         paint: {
      //           "raster-opacity": 1,
      //         },
      //       });
      //     });
      //   }
      // }
    }
  } else {
    data.features &&
      data.features.map((i) => {
        if (map.getLayer(`overlay_${i.id}`)) {
          map.removeLayer(`overlay_${i.id}`);
        }
        if (map.getSource(`${i.id}_image`)) {
          map.removeSource(`${i.id}_image`);
        }
      });
    // if (filter.find((da) => da.name === "Utilisation_plan")) {
    //   map.addSource("myImageSource", {
    //     type: "image",
    //     // url: SiteUtilisation,
    //     url:
    //       config.static_url +
    //       "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
    //     coordinates: [
    //       [103.97765608050582, 1.3215100806166618],
    //       [103.99945735241766, 1.3732393557740608],
    //       [104.02104408827296, 1.364061527213721],
    //       [103.999194686995, 1.3124846682241724],
    //     ],
    //   });

    //   map.addLayer({
    //     id: "overlay",
    //     source: "myImageSource",
    //     type: "raster",
    //     paint: {
    //       "raster-opacity": 1,
    //     },
    //   });
    // }

    if (filter.find((da) => da.name === "Satilite_plan")) {
      const SatiliteFilter = filter.find((i) => i.name === "Satilite_plan");
      const dataToShow = data.features.find(
        (i) => i.id === SatiliteFilter.list[0]
      );
      if (SatiliteFilter.list.length > 0) {
        data.features &&
          data.features.map((i) => {
            const find = SatiliteFilter.list.find((j) => j === i.id);
            if (find) {
              map.addSource(`${i.id}_image`, {
                type: "image",
                // url:
                //   config.static_url +
                //   "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
                // coordinates: [
                //   [103.97238513547705, 1.31906072285895],
                //   [103.99692457768754, 1.3775179812094223],
                //   [104.03453920424442, 1.3611841571367904],
                //   [104.0098499234432, 1.3027298026447482],
                // ],
                url: i.image_url,
                coordinates: [
                  [
                    JSON.parse(i.corner_points[0][1]),
                    JSON.parse(i.corner_points[0][0]),
                  ],
                  [
                    JSON.parse(i.corner_points[1][1]),
                    JSON.parse(i.corner_points[1][0]),
                  ],
                  [
                    JSON.parse(i.corner_points[2][1]),
                    JSON.parse(i.corner_points[2][0]),
                  ],
                  [
                    JSON.parse(i.corner_points[3][1]),
                    JSON.parse(i.corner_points[3][0]),
                  ],
                ],
              });

              map.addLayer({
                id: `overlay_${i.id}`,
                source: `${i.id}_image`,
                type: "raster",
                paint: {
                  "raster-opacity": 1,
                },
              });
            }
          });
      }
      // if (dataToShow) {
      //   map.addSource("myImageSource", {
      //     type: "image",
      //     // url:
      //     //   config.static_url +
      //     //   "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
      //     url: dataToShow.image_url,
      //     // coordinates: [
      //     //   [103.97238513547705, 1.31906072285895],
      //     //   [103.99692457768754, 1.3775179812094223],
      //     //   [104.03453920424442, 1.3611841571367904],
      //     //   [104.0098499234432, 1.3027298026447482],
      //     // ],
      //     coordinates: [
      //       [
      //         JSON.parse(dataToShow.corner_points[0][1]),
      //         JSON.parse(dataToShow.corner_points[0][0]),
      //       ],
      //       [
      //         JSON.parse(dataToShow.corner_points[1][1]),
      //         JSON.parse(dataToShow.corner_points[1][0]),
      //       ],
      //       [
      //         JSON.parse(dataToShow.corner_points[2][1]),
      //         JSON.parse(dataToShow.corner_points[2][0]),
      //       ],
      //       [
      //         JSON.parse(dataToShow.corner_points[3][1]),
      //         JSON.parse(dataToShow.corner_points[3][0]),
      //       ],
      //     ],
      //   });

      //   map.addLayer({
      //     id: "overlay",
      //     source: "myImageSource",
      //     type: "raster",
      //     paint: {
      //       "raster-opacity": 1,
      //     },
      //   });
      // }
    }
  }
};

export const Utilisation_plan_SVY21 = (map, CAG_RW, Site_utilisation) => {
  console.log(CAG_RW);
  var cv = new SVY21();
  let Uti_SVY21_ = [];
  CAG_RW[0].geometries.map((data) => {
    let coordinates = [];
    for (let i = 0; i < data.data.attributes.position.array.length; i = i + 3) {
      if (
        data.data.attributes.position.array[i] > 0 ||
        data.data.attributes.position.array[i + 1] > 0
      ) {
        var resultLatLon = cv.computeLatLon(
          data.data.attributes.position.array[i + 1],
          data.data.attributes.position.array[i]
        );
        coordinates.push([resultLatLon.lon, resultLatLon.lat]);
      }
    }
    Uti_SVY21_.push({
      type: "Feature",
      id: data.name,
      package: data.name,
      title: data.name,
      layer: "Utilisation_plan",
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
      properties: {},
    });
  });
  map.on("load", () => {
    map.addSource("areas1", {
      type: "geojson",
      data: {
        features: Site_utilisation[0].features,
        type: "FeatureCollection",
      },
      generateId: true,
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
      id: "areas1",
      type: "line",
      source: "areas1", // reference the data source
      sourceLayer: "original",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#910A9A",
        "line-width": 9,
        "line-opacity": 0.7,
      },
    });
  });
};

export const Satilite_plan = (map, filter, load) => {
  if (load) {
    if (filter.find((da) => da.name === "Satilite_plan")) {
      map.on("load", function () {
        map.addSource("myImageSource3", {
          type: "image",
          url:
            config.static_url +
            "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
          coordinates: [
            [103.97238513547705, 1.31906072285895],
            [103.99692457768754, 1.3775179812094223],
            [104.03453920424442, 1.3611841571367904],
            [104.0098499234432, 1.3027298026447482],
          ],
        });
        map.addLayer({
          id: "overlay3",
          source: "myImageSource3",
          type: "raster",
          paint: {
            "raster-opacity": 1,
          },
        });
      });
    }
  } else {
    if (map.getLayer("overlay3")) {
      map.removeLayer("overlay3");
    }
    if (map.getSource("myImageSource3")) {
      map.removeSource("myImageSource3");
    }
    if (filter.find((da) => da.name === "Satilite_plan")) {
      map.addSource("myImageSource3", {
        type: "image",
        url:
          config.static_url +
          "/staticfiles/image/RW_SP-1001AQ_WS%20SVY21%20[binded%20Aug23]_enlarged%20text30mm_230925.png",
        coordinates: [
          [103.97238513547705, 1.31906072285895],
          [103.99692457768754, 1.3775179812094223],
          [104.03453920424442, 1.3611841571367904],
          [104.0098499234432, 1.3027298026447482],
        ],
      });
      map.addLayer({
        id: "overlay3",
        source: "myImageSource3",
        type: "raster",
        paint: {
          "raster-opacity": 1,
        },
      });
    }
  }
};

export const RemoveLabel = (map) => {
  map.on("load", () => {
    map.getStyle().layers.forEach((l) => {
      if (l.type == "symbol") map.setLayoutProperty(l.id, "visibility", "none");
    });
  });
};

export const RoadName = (map, filter, load, roadName) => {
  if (load) {
    if (filter.find((da) => da.name === "Road_name")) {
      map.on("load", () => {
        roadName[0].features.map((ele) => {
          const source_Name = `roadLine_${ele.properties.Name}`;
          map.addSource(source_Name, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: ele.geometry.coordinates,
              },
              properties: {
                Name: ele.properties.Name,
              },
            },
          });
          map.addLayer({
            id: source_Name + "_outline",
            type: "line",
            source: source_Name,
            layout: {},
            paint: {
              "line-color": "#ffffff",
              "line-opacity": 0.7,
              "line-width": 2,
            },
          });
          map.addLayer({
            id: source_Name + "_text",
            type: "symbol",
            source: source_Name, // reference the data source
            layout: {
              "symbol-placement": "line-center",
              "text-field": [
                "format",
                ["upcase", ["get", "Name"]],
                { "font-scale": 0.7 },
              ],
              "text-font": ["Open Sans Bold"],
              "text-offset": [0, -0.5],
            },
            paint: {
              "text-color": "#ffffff",
              "text-opacity": 1,
            },
          });
        });
      });
    }
  } else {
    roadName[0].features.map((ele) => {
      const source_Name = `roadLine_${ele.properties.Name}`;
      if (map.getLayer(source_Name + "_outline")) {
        map.removeLayer(source_Name + "_outline");
      }
      if (map.getLayer(source_Name + "_text")) {
        map.removeLayer(source_Name + "_text");
      }
      if (map.getSource(source_Name)) {
        map.removeSource(source_Name);
      }
    });
    if (filter.find((da) => da.name === "Road_name")) {
      roadName[0].features.map((ele) => {
        const source_Name = `roadLine_${ele.properties.Name}`;
        map.addSource(source_Name, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: ele.geometry.coordinates,
            },
            properties: {
              Name: ele.properties.Name,
            },
          },
        });
        map.addLayer({
          id: source_Name + "_outline",
          type: "line",
          source: source_Name,
          layout: {},
          paint: {
            "line-color": "#ffffff",
            "line-opacity": 0.7,
            "line-width": 2,
          },
        });
        map.addLayer({
          id: source_Name + "_text",
          type: "symbol",
          source: source_Name, // reference the data source
          layout: {
            "symbol-placement": "line-center",
            "text-field": [
              "format",
              ["upcase", ["get", "Name"]],
              { "font-scale": 0.7 },
            ],
            "text-font": ["Open Sans Bold"],
            "text-offset": [0, -0.5],
          },
          paint: {
            "text-color": "#ffffff",
            "text-opacity": 1,
          },
        });
      });
    }
  }
};

export const SitrepMarker = (incident, sitrep, map) => {
  const MarkerData = [];
  sitrep.incident_ids.map((data) => {
    const find = incident.incidents.find((da) => da.id === data.id);
    console.log(find);
    if (find) {
      if (!MarkerData.find((dat) => dat.id === find.id)) {
        find.map_location_points.map((l) => {
          MarkerData.push({
            geometry: {
              coordinates: [JSON.parse(l[1]), JSON.parse(l[0])],
            },
            title: find.title,
            incident_date: find.incident_date,
            incident_location: find.incident_location,
            incident_type: find.incident_type,
            package: find.package,
            id: find.id,
          });
        });
      }
    }
  });
  Site_Utilisation_plan(map, [{ name: "Utilisation_plan", list: [] }], true);
  SetSitrepMarker(MarkerData, map);
};

const SetSitrepMarker = (Marker, map) => {
  for (const feature of Marker) {
    const popupContent = document.createElement("div");
    popupContent.className = "marker";
    popupContent.style.backgroundImage = `url(${MapIncident})`;
    popupContent.style.height = "30px";
    popupContent.style.width = "21px";
    const Incident_div = document.createElement("div");
    Incident_div.innerHTML = `<div>
    <div class=Map_popup_incident_main_box>
    <div class=Map_popup_incident_title>${feature.title}</div>
    <div class=Map_popup_incident_sec_box>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Date of Incident</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_date}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Location</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_location}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Classification of Incident</div>
    <div class=Map_popup_incident_sec_data>${feature.incident_type}</div>
    </div>
    <div class=Map_popup_incident_50W>
    <div class=Map_popup_incident_sec_title>Package</div>
    <div class=Map_popup_incident_sec_data>${feature.package}</div>
    </div>
    </div>
    </div>
    <div class=Map_popup_footer><button class=button_map id=IncidentOnClick name=${feature.id} ><div class=Map_popup_MoreDeatail id="${feature.id}">Click here for more details </div></button></div>
    </div>`;
    const marker = new mapboxgl.Marker(popupContent)
      .setLngLat(feature.geometry.coordinates)
      .setPopup(new mapboxgl.Popup({ offset: 26 }).setDOMContent(Incident_div))
      .addTo(map);
  }
};

export const setWMS = (map, url) => {
  if (map.getLayer("wms-layer")) {
    map.removeLayer("wms-layer");
  }
  if (map.getSource("wms")) {
    map.removeSource("wms");
  }
  // map.on("load", () => {
  console.log("this one is called", url);
  map.addSource("wms", {
    type: "raster",
    tiles: [
      //   "https://sms-access.changiairport.com/prod-api/freedo-geoserver/geoserver/cag3/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&TRANSPARENT=TRUE&LAYERS=cag3:air_package&STYLES=&SRS=EPSG:3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256&FORMAT=image/png&CQL_FILTER=contractor_name IN ('PKG4A','ARC','PKG1','PKG3','PKG2','T2C','T316','CR102')"
      url,
    ],
    tileSize: 256,
  });

  map.addLayer({
    id: "wms-layer",
    type: "raster",
    source: "wms",
    paint: {},
  });
  // });
};
